import React from "react";
import "./home.css";
import "../../assets/css/aos.css";
import { toast } from "react-toastify";
function HomePage() {
  const download =() => {
    toast.info("Comming Soon...")
  } 
  return (
    <div className="container-full" id="home">
      <div className="hero-section">
        <div className="wave">
          <svg
            width="100%"
            height="355px"
            viewBox="0 0 1920 355"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Apple-TV"
                transform="translate(0.000000, -402.000000)"
                fill="#FFFFFF"
              >
                <path
                  d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div className="container cont">
        <div className="row align-items-center">
          <div className="col-12 hero-text-image">
            <div className="row">
              <div className="col-lg-8 text-center left text-lg-start">
                <h1 data-aos="fade-right" className="aos-init h1 aos-animate">
                  Seamless Solutions for Your Financial Journey!
                </h1>
                <p
                  className="mb-5 p aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <span className="punchline">
                  Discover the ultimate banking solutions at your fingertips.😎
                  </span>{" "}
                  <br />
                  <span className="break"></span>
                  Download our state-of-the-art banking apps today and unlock a
                  world of convenience and efficiency. 
                  Seamlessly manage your
                  accounts, transfer funds, pay bills, and track your
                  transactions in real-time. 
                </p>
                <p
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-offset="-500"
                  className="aos-init aos-animate"
                >
                  <a
                    onClick={download}
                    target="_blank"
                    className="btn btn-white"
                  >
                    Download Apps
                  </a>
                </p>
              </div>
              <div className="col-lg-4 right iphone-wrap">
                <img
                  src="https://ucarecdn.com/e3b4ce02-2a56-45e5-b2cf-022aa13c8b1c/"
                  alt="Image"
                  className="phone-2 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-delay="200"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
