import { motion } from "framer-motion";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "./contactUs.css";


function ContactUs() {

  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  })

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setContactInfo((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const submit = () => {
    if (!contactInfo.name) {
      toast.warn("Enter name")
      return
    }
    if (!contactInfo.email) {
      toast.warning("Enter your email")
      return
    }
    const emailRegexPattern: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = (email: string): boolean => {
      return emailRegexPattern.test(email);
    };
    if (!isValidEmail(contactInfo.email)) {
      toast.warning("Enter a valid email Address")
      return
    }
    if (!contactInfo.subject) {
      toast.warn("Enter email subject")
      return
    }
    if (!contactInfo.message) {
      toast.warn("Enter email Message")
      return
    }
    toast.success(`Thanks ${contactInfo.name} for contact us.\nWe will get back to you soon`)
    setContactInfo({
      name: "",
      email: "",
      subject: "",
      message: ""
    })
  }

  return (
    <div className="container" id="contact-us">
      <div className="contact-us-section">
      <div className="row justify-content-center text-center contact-us-section-heading">
            <div className="col-md-10">
              <h2 className="section-heading">
              Contact Us
              </h2>
            </div>
          </div>
        {/* <div className="contact-us-title">Contact Us</div> */}
        <div className="contact-us-description">
          Laxnit Solutions offers exceptional services, including bulk SMS, core
          banking, agency banking, and mobile banking. Our goal is to provide
          effective communication and reliable financial solutions for your
          business. Contact us via our website or app to transform your business
          communication and banking experience today.
        </div>
      </div>
      <div className="contact-us-details">
        <div className="contact-form">
          <div className="header-form">
            <div className="mb-3 contact-form-field">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="contact-form-field"
              >
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" className="form-control" id="name" name="name" aria-describedby="emailHelp" placeholder="Your Name" value={contactInfo.name} onChange={handleInputChange} />
              </motion.div>

            </div>
            <div className="mb-3 ms-4 contact-form-field">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="contact-form-field"
              >
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" name="email" placeholder="Your Email" value={contactInfo.email} onChange={handleInputChange} />
              </motion.div>

            </div>
          </div>
          <div className="subject">
            <div className="mb-3 contact-form-field">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="contact-form-field"
              >
                <label htmlFor="subject" className="form-label-subject">Subject</label>
                <input type="text" className="form-control form-control-subject" id="subject" aria-describedby="emailHelp" placeholder="Email Subject" name="subject" value={contactInfo.subject} onChange={handleInputChange} />
              </motion.div>

            </div>
          </div>
          <div className="subject">
            <div className="mb-3 contact-form-field">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="contact-form-field"
              >
                <label htmlFor="message" className="form-label-subject">Message</label>
                <textarea className="form-control form-control-message" name="message" id="message" aria-describedby="emailHelp" placeholder="Type Message" value={contactInfo.message} onChange={handleInputChange} />
              </motion.div>

            </div>
          </div>
          <div className="contact-button">
            <button className="button-send" onClick={submit}>Send</button>
          </div>
        </div>
        <div className="contact-handles">
          <div className="address">
            <div className="contact-form-headers">
              Address
            </div>
            <span>Nairobi, Kenya.</span>
          </div>
          <div className="address mt-1">
            <div className="contact-form-headers ">
              Email
            </div>
            <span>Laxnit@services.com</span>
          </div>
          <div className="address mt-1">
            <div className="contact-form-headers">
              Phone
            </div>
            <span>+254-722-000-000</span>
          </div>
        </div>

      </div>
    </div>
  );
}

export default ContactUs;
