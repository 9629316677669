import React from "react";
import Navbar from "./app/components/navbar";
import Footer from "./app/components/footer/footer";
import "./App.css";
import HomePage from "./app/home-page/HomePage";
import Services from "./app/services/Services";
import ContactUs from "./app/contact-us/ContactUs";

function App() {
  return (
    <div className="App">
      <Navbar />
      <HomePage/>
      <Services />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
