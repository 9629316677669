import React from "react";
import { FaInstagram, FaTwitter, FaLinkedin, FaFacebook } from "react-icons/fa";
import "./footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="sb__footer section__padding">
        <div className="sb__footer-links">
          <div className="sb__footer-links-div about">
            <h4>About Us</h4>
            <p className="contact-us-description">
              At Laxnit, we are a leading fintech company dedicated to
              transforming the way people manage their finances. With our
              innovative solutions and cutting-edge technology, we empower
              individuals and businesses to take control of their financial
              lives with ease and confidence. Our team of experts is driven by a
              passion for delivering exceptional financial experiences,
              providing seamless and secure solutions tailored to the unique
              needs of our customers. From intuitive mobile apps to advanced
              financial tools, we are committed to revolutionizing the way
              people save, invest, and transact. Join us on this exciting
              journey as we redefine the future of finance.
            </p>
            <div className="socialmedia">
              <p>
                <FaInstagram />
              </p>
              <p>
                <FaTwitter />
              </p>
              <p>
                <FaLinkedin />
              </p>
              <p>
                <FaFacebook />
              </p>
            </div>
          </div>

          <div className="sb__footer-links-div">
            <h4>Navigation</h4>
            <a href="#whyus">Why Choose Us</a> <br />
            <a href="#contact-us">Contact US</a>
          </div>

          <div className="sb__footer-links-div">
            <h4>Solutions</h4>
            <a href="#">Bulk SMS</a> <br />
            <a href="#">Mobile Banking</a> <br />
            <a href="#">Agency Banking</a> <br />
            <a href="#">Core Banking System</a>
          </div>
        </div>

        <hr />
        <div className="sb_footer-below">
          <div className="sb_footer-copyright">
            <p>@{new Date().getFullYear()} Laxnit. All rights reserved.</p>
          </div>
          <div className="sb_footer-below-links">
            <a href="#">
              <div>
                <p>Privacy</p>
              </div>
            </a>
            <a href="#">
              <div>
                <p>Terms & Conditions</p>
              </div>
            </a>
            <a href="#">
              <div>
                <p>Security</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
