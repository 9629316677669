import React from 'react'
import './services.css'
import * as GiIcon  from "react-icons/gi"
import * as FaIcon from 'react-icons/fa'
import * as MdIcon from 'react-icons/md'

function Services() {
  return (
    <div className="container" id="whyus">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-10">
              <h2 className="section-heading">
                Comprehensive Financial Solutions for Modern Businesses
              </h2>
            </div>
          </div>
          <div className="services">
            <div className="service">
              <div className="feature-1 text-center">
                <div className="wrap-icon icon-1">
                  <FaIcon.FaSms className='system-icon' />
                </div>
                <h3 className="mb-3">Bulk SMS</h3>
                <p>
                  Our bulk SMS service is a powerful tool for businesses to
                  communicate with customers, staff, and other stakeholders.
                  Send personalized messages in bulk, track delivery and
                  engagement, and stay connected with your audience like never
                  before.
                </p>
              </div>
            </div>
            <div className="service">
              <div className="feature-1 text-center">
                <div className="wrap-icon icon-1">
                  <MdIcon.MdMoney className='system-icon' />
                </div>
                <h3 className="mb-3">Core Banking System</h3>
                <p>
                  Our cutting-edge core banking system powers all of our
                  financial services, ensuring that your transactions are fast,
                  secure, and reliable. With real-time updates and robust
                  security features, you can trust us with your financial needs.
                </p>
              </div>
            </div>
            <div className="service">
              <div className="feature-1 text-center">
                <div className="wrap-icon icon-1">
                  <i className="bi bi-cash-coin"></i>
                  <GiIcon.GiBanknote className='system-icon'/>
                </div>
                <h3 className="mb-3">Mobile Banking</h3>
                <p>
                  Access your bank account anytime, anywhere with our mobile
                  banking service. Our user-friendly app makes it easy to
                  transfer money, pay bills, and manage your finances on-the-go.
                </p>
              </div>
            </div>
            <div className="service">
              <div className="feature-1 text-center">
                <div className="wrap-icon icon-1">
                  <MdIcon.MdRealEstateAgent className='system-icon' />
                </div>
                <h3 className="mb-3">Agency Banking</h3>
                <p>
                  We offer agency banking services that allow you to perform a wide range of transactions right in your neighborhood. From cash deposits and withdrawals to account opening and loan disbursement, our network of agents makes banking more accessible for everyone.
                </p>
              </div>
            </div>
          </div>
        </div>
  )
}

export default Services
